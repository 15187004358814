const colors = {
  green: {
    '100': '#DAF1DC',
    '400': '#6CC673',
    '500': '#46B650',
    '600': '#399340',
  },
  blue: {
    '50': '#FEFFF5',
    '100': '#FDFFEB',
    '200': '#FCFFD6',
    '300': '#FAFFC2',
    '400': '#F8FFAD',
    '500': '#F7FF98',
    '600': '#F0FF47',
    '700': '#BAC91D',
    '800': '#858F14',
    '900': '#43480A',
  },
  red: {
    '500': '#FC4832',
    '100': '#FEDBD7',
  },
  orange: {
    '100': '#FFF3E5',
  },
  gray: {
    '50': '#E5E8E8', // <-
    '100': '#D6D9DA',
    '200': '#C7CACB',
    '300': '#AEB1B1',
    '400': '#7D8081',
    '500': '#474B4D',
    '600': '#36393A',
    '700': '#2A2D2E',
    '800': '#1D2021',
    '900': '#111415',
  },
  black: '#0A0A0A',
  white: '#FAFAFA',
  blackAlpha: {
    '50': 'RGBA(10, 10, 10, 0.05)',
    '100': 'RGBA(10, 10, 10, 0.10)',
    '200': 'RGBA(10, 10, 10, 0.20)',
    '300': 'RGBA(10, 10, 10, 0.30)',
    '400': 'RGBA(10, 10, 10, 0.40)',
    '500': 'RGBA(10, 10, 10, 0.50)',
    '600': 'RGBA(10, 10, 10, 0.60)',
    '700': 'RGBA(10, 10, 10, 0.70)',
    '800': 'RGBA(16, 17, 18, 0.80)',
    '900': 'RGBA(16, 17, 18, 0.90)',
  },
  github: '#171923',
  telegram: '#2775CA',
  linkedin: '#1564BA',
  discord: '#9747FF',
  slack: '#1BA27A',
  twitter: '#000000',
  opensea: '#2081E2',
  facebook: '#4460A0',
  medium: '#231F20',
  reddit: '#FF4500',
  celo: '#FCFF52',
};

export default colors;
